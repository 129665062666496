
import { FileLoaderManager } from "../../application/FileLoaderManager";

export class TileTreeLoaderLocal {

    #tileSet = null;
    #folderPath = undefined;
    #viewer = undefined;

    constructor(tileSet, tileSetFolder, viewer) {
        this.#tileSet = tileSet;
        this.#folderPath = tileSetFolder;
        this.#viewer = viewer;
    }

    dtor() {
        this.#tileSet = null;
    }

    getTileSet() {
        return this.#tileSet;
    }

    isReady() {
        const loader = FileLoaderManager.getFileLoaderForExtension("gltf");
        return !!loader;
    }

    // Local uri from content.uri entry of a tile, e.g., "8/0/output.gltf"
    async loadTile(contentUri) {             
        const path = `${this.#folderPath}/${contentUri}`;
        
        const model = await TileTreeLoaderLocal.#loadFile(path, this.#viewer);

        this.#viewer.impl._signalMeshAvailable();

        return model;
    }

    static async #loadFile(path, viewer) {
        return new Promise((resolve, reject) => {
            const GLTFLoader = FileLoaderManager.getFileLoaderForExtension("gltf");
            const gltfLoader = new GLTFLoader(viewer.impl);
        
            gltfLoader.loadFile(path, {}, () => {
                resolve(gltfLoader.model);
            });
        });
    }
}