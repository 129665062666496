
/**
 * The companion type encodes two information hints. First, the render sequence hint which specifies
 * whether the companion should be rendered before or after the primary rendering (progressive rendering of
 * the main scene). The second hint describes the renderer that should be used. For now, only three.js is
 * supported. We might want to add additional render passes or delegates in the future and can do so here.
 *
 * Supposed to be exposed in `Autodesk.Viewing.CompanionType`.
 *
 * Note: for now, there are several ways on how enums and enum-like "things" are declared within this code base.
 * Just an argument in favor to either switch to latest ES6 features or TypeScript.
 */
export const CompanionType = Object.freeze({
    // THREE scene that is supposed to be rendered before any internal frames is rendered.
    THREE_SCENE_BEFORE: Symbol('ENUM_THREE_SCENE_BEFORE'),
    // THREE scene that is supposed to be rendered after all internal frames are rendered.
    THREE_SCENE_AFTER: Symbol('ENUM_THREE_SCENE_AFTER'),
});
