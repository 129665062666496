import * as THREE from "three";

const tmpVec3 = new THREE.Vector3();
const tmp2Vec3 = new THREE.Vector3();

// Represents an oriented 3D Bounding-Volume, also compatible with 3d Tiles bounding volumes.
// Offers convenience checks to determine whether the Tile is visible in a given Frustum or not.
export class TileBoundary {

    center = new THREE.Vector3();
    xAxis  = new THREE.Vector3();
    yAxis  = new THREE.Vector3();
    zAxis  = new THREE.Vector3();

    constructor() {
    }

    /**
     * Initializes the Tile Bounding Volume from an array of 12 floating numbers
     * center, xAxis, yAxis, zAxis. Each axis vector indicates axis and half-length,
     * according to 3d tiles spec: https://docs.ogc.org/cs/18-053r2/18-053r2.html#33.
     *
     * @param {float[]} [data] - Array of 12 floats.
     * @returns {TileBoundary} A Tile Bounding Volume initialized from given array.
     */
    fromArray(data) {
        this.center.fromArray(data);
        this.xAxis.fromArray(data, 3);
        this.yAxis.fromArray(data, 6);
        this.zAxis.fromArray(data, 9);
        return this;
    }

    /**
     * Converts the Tile Bounding Volume into an AABB.
     * @returns {THREE.Box3} The AABB representing this Tile Bounding Volume bounds.
     */    
    toAABB() {
        // minimum point
        tmpVec3.set(
            this.center.x - this.xAxis.x - this.yAxis.x - this.zAxis.x,
            this.center.y - this.xAxis.y - this.yAxis.y - this.zAxis.y,
            this.center.z - this.xAxis.z - this.yAxis.z - this.zAxis.z);

        // maximum point
        tmp2Vec3.set(
            this.center.x + this.xAxis.x + this.yAxis.x + this.zAxis.x,
            this.center.y + this.xAxis.y + this.yAxis.y + this.zAxis.y,
            this.center.z + this.xAxis.z + this.yAxis.z + this.zAxis.z);
    
        const aabb = new THREE.Box3(tmpVec3, tmp2Vec3);
        return aabb;
    }

    /**
     * Returns the corner point by index.
     *
     * @param {int} [index] - The corner point's index, in [0,5).
     * @param {THREE.Vector3} [target] - A pre-allocated temporary vector to copy the corner point's value into.
     * @returns {THREE.Vector3} The corner point by index.
     */    
    #getPoint(index, target) {
        target.copy(this.center);
        if (index & 1) target.add(this.xAxis); else target.sub(this.xAxis);
        if (index & 2) target.add(this.yAxis); else target.sub(this.yAxis);
        if (index & 4) target.add(this.zAxis); else target.sub(this.zAxis);
        return target;
    }

    /**
     * Conservative frustum test: Consider box as outside frustum if it is fully outside wrt.
     * to at least one of the frustum planes.
     *
     * @param {FrustumIntersector} [frustum] - The current frustum.
     * @returns {bool} True if this Tile Bounding Volume is outside the given frustum, false otherwise.
     */
    boxOutsideFrustum(frustum) {
        // Check 6 frustum planes
        const planes = frustum.frustum.planes;
        for (let i = 0; i < 6; i++) {
            // Return true if box is fully outside wrt. to one of the planes
            const plane = planes[i];
            if (this.#outsideFrustumPlane(plane)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Checks whether all the points of this TileBox are located on the negative side of a given Frustum plane.
     *
     * @param {THREE.Plane} [plane] - A plane of a Frustum.
     * @returns {bool} True if signed plane distance is negative for all points of this Tile Bounding Volume.
     */
    #outsideFrustumPlane(plane) {
        for (let i = 0; i < 6; i++) {
            const p = this.#getPoint(i, tmpVec3);
            const d = plane.distanceToPoint(p);
            if (d >= 0.0) {
                return false;
            }
        }
        return true;
    }
}