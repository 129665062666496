import { Model } from "../../application/Model";

import { TileBoundary } from "../../wgs/scene/TileBoundary";

import * as THREE from "three";

async function loadJson(url) {
    return await fetch(url)
    .then(response => {
        if (!response.ok) {
            console.error(`Failed to load json: ${url}, status: ${response.status}`);
        } else {
            return response.json();
        }
    });
}

export class ThreeDTilesLoader {

    #viewer = undefined;

    // Only signaling the event will be sent out later
    // TODO dispatch this event, when the first geometry is actually loaded
    notifiesFirstPixel = true;

    static acceptsMimeType(mimeType) {
        return mimeType == "application/vnd.autodesk.tiles.glb+json";
    }

    constructor(viewerImpl, config) {
        this.#viewer = viewerImpl.api;

        // TODO remove that dependency used to dispatch events in RenderModel through 'loader.viewer3DImpl.api'
        this.viewer3DImpl = viewerImpl;
    }

    #prepareData(tileSet) {
        tileSet.numGeoms = 0;
        tileSet.is2d = false;
        tileSet.isOTG = false;
        tileSet.fragments = [];        
        tileSet.loadOptions = {};

        tileSet.bbox = this.#getBounds(tileSet);
    }

    static #addParentPointers(tile) {
        if (!tile.children) {
            return;
        }
    
        tile.children.forEach((child, index) => {
            child.parent = tile;
            child.childIndex = index;
            this.#addParentPointers(child);
        });
    };

    loadFile(url, options, onDone, onWorkerStart) {
        this.#viewer.impl._signalNoMeshes();

        loadJson(url).then(tileSet => {
            ThreeDTilesLoader.#addParentPointers(tileSet.root);
            this.#prepareData(tileSet);

            const model = new Model(tileSet);
            model.loader = this;
            model.initialize();

            onDone(undefined, model);
        });        

        return true;    
    }

    #getBounds(tileSet) {
        const root = tileSet.root;
        const box = root.boundingVolume.box;
        if (!box) {
            // fallback to at least a valid object to avoid lots of warnings in camera computation
            return new THREE.Box3(new THREE.Vector3(0,0,0), new THREE.Vector3(1,1,1));
        }               

        const obb = new TileBoundary();
        obb.fromArray(box);
        const bounds = obb.toAABB();

        return bounds;
    }    
}