import * as THREE from 'three';

let ExportHelper;

                               

                                            
                                            
                                            

   
                                      
                                               
  
                 
                 
                     
                                      
  
                  
                            
  
                                   
                      
                       
  
                         
  
               
    
   

                                                           
                                                
                                              
                                              
                         
                                          
     

                                   
                                                      
     
 

                                                 
                             
                                        

                            

                                            

                                                 

                        

                      
                                    

                                           

                                                          
                        

                                                

                                   
     

                    
                           
  
                                      
  
                              
                              
                                                    
  
                  
     

                               
                                                  
     
 

                                     

         

ExportHelper = THREE.PointCloudMaterial;

          

export { ExportHelper as LMVPointCloudMaterial, ExportHelper as LMVPointsMaterial};
