import * as THREE from 'three';

let ExportHelper;

                               

                                            
                                            
                                               

   
                                      
                                               
  
                 
                 
                    
                    
                       
                     
  
                                      
  
                                           
  
                                          
                       
  
                                            
                           
  
                                              
  
                                           
  
                                                                          
                            
                          
                             
  
                                 
                                   
                      
                       
  
                         
                                
  
                                                                         
  
                     
                         
                         
  
              
    
   

                                                         

                                                                  
                                                                     
                                                                 
                                                                                
                                                                                       
                                                                          
                                                                                               
                                
                                                                         
                                                                                  
     

                          
                                                        

                                                    

                              
                                                            
 

                                                                                                            
                                                
                             
                                        

                                                               
                           

                                           

                                                          
                                                  
                                                  
                            

                           

                        

                             

                            
                           

                              
                                                   

                                

                             

                           
                                               
                              
                                    

                                                          
                        

                                 

                               
                                    
                                        
                                         

                                           

                              
                                  
                                  

                                                                                                  
                                                

                                                          
                                                     
                                                          
                                                     
                                                          
                                               
                                                    

                                   
     

                                                                  
                                                                

                                                            
                                                          

                    
                             
      
                                             
                                      
                                            
                                            
                                          

                                  

                              

                                        

                                      
                                          

                                          
                                                  

                                              

                                        

                                    
                                      
                                                
                                                      

                              

                                              

                                          
                                                            
                                                        
                                                          

                                                

                                        
                                                
                                                

                    
     

                               
                                        
                                                
     

                                             
                                                                                                 
                                 
                                                                                      
                                                                        
                                                                  
                                                  
                                                    
   
 

                                                             

                                    

         

ExportHelper = THREE.MeshPhongMaterial;

          

export { ExportHelper as LMVMeshPhongMaterial };
