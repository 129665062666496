
                               

   
                                                                           
                                                                           
                                        
   
                                                 
                            
                                                          
   
                           
                                                     
   
           
                               
                           
                                       
                             
     
   
          
                                               
           
                                                                                                    
                                                                 
     
    
 

   
                                                                                                          
                                         
  
                                                                      
                                                                                                    
   
                                              
                                       
                                                               
     
                                          
                                        
     
                     
 

   
                                                                                              
  
                                                                                                 
   
                                                 
                                 
                                           
     
 

          

/**
 * Checks if any attribute is an interleaved buffer, as LMV assumes all or none attributes are interleaved
 * @param {BufferGeometry} geom
 * @returns {boolean}
 */
export function isInterleavedGeometry(geom) {
  let result;
                                 
  result = !!geom.vb;
           
                                    
                                                               
          
   
            
  return result;
}

/**
 * Convenience accessor to the indices (index buffer or 'ib') of a given geometry.
 *
 * @param {BufferGeometry} geometry - Geometry to grab the index buffer array from.
 * @returns {Uint16Array} - Typed array containing the indices of the given geometry.
 */
export function getIndexBufferArray(geometry) {
    let ib;
                                   
    ib = geometry.ib;
             
                                                                                                                             
                               
              
    return ib;
}

/**
 * Convenience accessor to the edge/line indices (indexline buffer or 'iblines') of a given geometry.
 *
 * @param {BufferGeometry} geometry - Geometry to grab the edge/line index buffer array from.
 * @returns {Uint16Array} - Typed array containing the edge/line indices of the given geometry.
 */
export function getLineIndexBufferArray(geometry) {
    let iblines;
                                   
    iblines = geometry.iblines;
             
                                         
              
    return iblines;
}

// @todo: An accurate implementation for the polygon count is in GeometryList.addGeometry.
// This function just captures the typical polyCount behavior used in most extensions.
export function getPolygonCount(geometry) {
    if(!geometry) {
        return 0;
    }

    let indexLength = 0;
                                   
    const ib = geometry.attributes.index?.array || geometry.ib;
    indexLength = ib.length;
             
                                        
                               
              

    return (indexLength / 3) || 0;
}

export function getByteSize(geom) {
    // @todo - To avoid wrong byte size reporting, the geometry should keep track of this itself.
    let res = 0;
                                   
    res = (geom.vb?.byteLength || 0) + (geom.ib?.byteLength || 0) + (geom.iblines?.byteLength || 0);
             
                                                               
                                               
                                                     
              
    return res;
}
