import * as THREE from 'three';
import {
  refreshUniformsIBL,
  refreshUniformsLmvCommon
} from './LmvMaterialUtils';

// Add clamping and inversion code for the simple Phong material perform any operations needed.
// This is done here because we have access to the clamp and inversion parameters. The macro #defined
// by this method can then be used elsewhere without knowledge of these parameters.
var getMapChunk = function(name, clampS, clampT, invert, emptyChunk) {
  var invertChunk = invert ? "1.0-" : "";
  var readChunk = "texture2D("+name+", (UV))";
  var conditionChunk = "";
  emptyChunk = emptyChunk || "vec4(0.0)";
  if (clampS && clampT)
      conditionChunk = "((UV).x < 0.0 || (UV).x > 1.0 || (UV).y < 0.0 || (UV).y > 1.0) ? "+emptyChunk+" : ";
  else if (clampS)
      conditionChunk = "((UV).x < 0.0 || (UV).x > 1.0) ? "+emptyChunk+" : ";
  else if (clampT)
      conditionChunk = "((UV).y < 0.0 || (UV).y > 1.0) ? "+emptyChunk+" : ";
  return "#define GET_"+name.toUpperCase()+"(UV) ("+conditionChunk+invertChunk+readChunk+")";
};

class LMVMaterial extends THREE.ShaderMaterial {
    constructor(...args){
      super(...args);
      // In contrast with THREE.Material base class, THREE.ShaderMaterial sets this property, which collides with LMV logic
      this.linewidth = undefined;

      this.extensions.drawBuffers = true;
      this.extensions.derivatives = true;
      this.extensions.shaderTextureLOD = true;
    }

    refreshUniforms(uniforms) {
      refreshUniformsLmvCommon(uniforms, this);
      refreshUniformsIBL(uniforms, this);
    }

    onBeforeCompile(shaderobject, renderer) {
      // LMVMaterials use their own instancing mechanism. This conflicts with the three.js mechanism,
      // which is only partially supported in our custom shaders. So we disable three.js' mechanism here.
      shaderobject.instancing = false;
      shaderobject.instancingColor = false;

      // Need to add defines to material so they are append before the headers set by WebGLProgram
      if (shaderobject.isWebGL2) {
        // Set glslVersion to GLSL3 to prevent WebGLProgram from defining the output
        shaderobject.glslVersion = THREE.GLSL3;
        
        this.defines["_LMVWEBGL2_"] = "";
        this.defines["gl_FragColor"] = "pc_fragColor";
      }

      if (shaderobject.isWebGL2 || !!shaderobject.extensionShaderTextureLOD){
        this.defines["HAVE_TEXTURE_LOD"] = "";
      }

      // NOTE: All the conditional defines below need to be covered in `customProgramCacheKey`.

      // Defines that are set based on certain conditions
      this.defines["TONEMAP_OUTPUT"] = `${this.tonemapOutput || 0}`;
      ((this.tonemapOutput !== undefined && this.tonemapOutput !== 0) || renderer.lmvGammaInput) ?
        this.defines["GAMMA_INPUT"] = "" : delete this.defines["GAMMA_INPUT"];

      this.defines["NUM_CUTPLANES"] = this.cutplanes ? this.cutplanes.length : 0;
      this.hatchPattern ? this.defines["HATCH_PATTERN"] = "" : delete this.defines["HATCH_PATTERN"];

      this.vertexIds ? this.defines["USE_VERTEX_ID"] = "" : delete this.defines["USE_VERTEX_ID"];

      this.packedNormals ? this.defines["UNPACK_NORMALS"] = "" : delete this.defines["UNPACK_NORMALS"];

      this.mrtNormals ? this.defines["MRT_NORMALS"] = "" : delete this.defines["MRT_NORMALS"];
      this.mrtIdBuffer ? this.defines["MRT_ID_BUFFER"] = "" : delete this.defines["MRT_ID_BUFFER"];
      this.mrtIdBuffer > 1 ? this.defines["MODEL_COLOR"] = "" : delete this.defines["MODEL_COLOR"];
      this.wideLines ? this.defines["WIDE_LINES"] = "" : delete this.defines["WIDE_LINES"];
      this.unpackPositions ? this.defines["UNPACK_POSITIONS"] = "" : delete this.defines["UNPACK_POSITIONS"];
      this.useInstancing ? this.defines["USE_LMV_INSTANCING"] = "" : delete this.defines["USE_LMV_INSTANCING"];

      (this.map && this.map.invert) ?
        this.defines["MAP_INVERT"] = "" : delete this.defines["MAP_INVERT"];

      if (this.useTiling) {
        this.defines["USE_TILING"] = "";
        this.defines["TILE_RANGE_X_MIN"] = this.tilingRepeatRange[0];
        this.defines["TILE_RANGE_Y_MIN"] = this.tilingRepeatRange[1];
        this.defines["TILE_RANGE_X_MAX"] = this.tilingRepeatRange[2];
        this.defines["TILE_RANGE_Y_MAX"] = this.tilingRepeatRange[3];
      } else {
        delete this.defines["USE_TILING"];
        delete this.defines["TILE_RANGE_X_MIN"];
        delete this.defines["TILE_RANGE_Y_MIN"];
        delete this.defines["TILE_RANGE_X_MAX"];
        delete this.defines["TILE_RANGE_Y_MAX"];
      }

      (this.envMap && this.envMap.RGBM) ? this.defines["ENV_RGBM"] = "" : delete this.defines["ENV_RGBM"];
      (this.envMap && this.envMap.GammaEncoded) ? this.defines["ENV_GAMMA"] = "" : delete this.defines["ENV_GAMMA"];

      this.irradianceMap ? this.defines["USE_IRRADIANCEMAP"] = "" : delete this.defines["USE_IRRADIANCEMAP"];
      (this.irradianceMap && this.irradianceMap.RGBM) ? this.defines["IRR_RGBM"] = "" : delete this.defines["IRR_RGBM"];
      (this.irradianceMap && this.irradianceMap.GammaEncoded) ?
        this.defines["IRR_GAMMA"] = "" : delete this.defines["IRR_GAMMA"];

      this.hasRoundCorner ? this.defines["USE_TILING_NORMAL"] = "" : delete this.defines["USE_TILING_NORMAL"];

      this.useRandomOffset ? this.defines["USE_TILING_RANDOM"] = "" : delete this.defines["USE_TILING_RANDOM"];

      renderer.getLoadingAnimationDuration?.() > 0 ?
        this.defines["LOADING_ANIMATION"] = "" : delete this.defines["LOADING_ANIMATION"];

      this.metal ? this.defines["METAL"] = "" : delete this.defines["METAL"];
      this.useBackgroundTexture ? this.defines["USE_BACKGROUND_TEXTURE"] = "" : delete this.defines["USE_BACKGROUND_TEXTURE"];

      Autodesk.Viewing.Private.patchShader(shaderobject, {
        fragmentHeader: [
          shaderobject.isWebGL2 ? "layout(location=0) out highp vec4 pc_fragColor;" : "",
          getMapChunk("map", shaderobject.mapClampS, shaderobject.mapClampT),
          getMapChunk("bumpMap", shaderobject.bumpMapClampS, shaderobject.bumpMapClampT),
          getMapChunk("normalMap", shaderobject.normalMapClampS, shaderobject.normalMapClampT),
          getMapChunk("specularMap", shaderobject.specularMapClampS, shaderobject.specularMapClampT),
          getMapChunk("alphaMap", shaderobject.alphaMapClampS, shaderobject.alphaMapClampT, shaderobject.alphaMapInvert),
          "uniform mat4 projectionMatrix;",
          "#if defined(USE_ENVMAP) || defined(USE_IRRADIANCEMAP)",
          "uniform mat4 viewMatrixInverse;",
          "#endif",      
        ].join('\n'),
      });
    }

    // This function returns a string that is based on the conditions used for all the conditional defines above.
    // If one of the conditions changes, the returned string will be different, causing a recompilation of the program,
    // and hence a re-run of `onBeforeCompile`, which will apply all defines as expected.
    customProgramCacheKey() {
      const customParameters = [
        this.tonemapOutput,
        this.renderer?.lmvGammaInput,
        this.cutplanes?.length,
        this.hatchPattern,
        !!this.vertexIds, // turning complex properties into a boolean, to avoid bloating this list
        this.packedNormals,
        this.mrtNormals,
        this.mrtIdBuffer,
        this.map && this.map.invert,
        this.useTiling,
        this.tilingRepeatRange?.toString(),
        this.irradianceMap && this.irradianceMap.RGBM,
        this.envMap && this.envMap.RGBM,
        this.envMap && this.envMap.GammaEncoded,
        !!this.irradianceMap,
        this.hasRoundCorner,
        this.useRandomOffset,
        this.renderer?.getLoadingAnimationDuration?.() > 0,
        this.metal,
        this.useBackgroundTexture,
        this.wideLines,
        this.unpackPositions,
        this.useInstancing
      ];

      return customParameters.concat(',');
    }

    _updateUniform(key, v) {
      if (this.uniforms[key] && this.uniforms[key].value !== v) {
        this.uniforms[key].value = v;
        return true;
      } else {
        return false;
      }
    }

    get cutplanes() {
      return this.uniforms?.cutplanes?.value;
    }
    set cutplanes(v) {
      if (this._updateUniform('cutplanes', v)) {
        this.needsUpdate = true;
      }
    }

    get hatchParams() { return this.uniforms?.hatchParams?.value;}
    set hatchParams(v) { this._updateUniform('hatchParams', v);}

    get hatchTintColor() { return this.uniforms?.hatchTintColor?.value;}
    set hatchTintColor(v) { this._updateUniform('hatchTintColor', v);}

    get hatchTintIntensity() { return this.uniforms?.hatchTintIntensity?.value;}
    set hatchTintIntensity(v) { this._updateUniform('hatchTintIntensity', v);}
}
// Mark it as custom LMV Material
LMVMaterial.prototype.isLmvMaterial = true;

export { LMVMaterial };