//Replacement for the THREE BasicMaterial adding cut plane support

import * as THREE from "three";
import { ShaderChunks as chunks } from 'ShaderChunksAlias';

import basic_vert from 'shaderAlias/basic_vert.glsl';
import basic_frag from 'shaderAlias/basic_frag.glsl';

let newThreejsUniforms = [];
                               
                      
                                       
                                  
                                    
     
                                                                             
                                                        
     
  
          

export let BasicShader = {

    uniforms: THREE.UniformsUtils.merge( [

        THREE.UniformsLib[ "common" ],
        THREE.UniformsLib[ "fog" ],
        THREE.UniformsLib[ "shadowmap" ],
        chunks.CutPlanesUniforms,
        chunks.IdUniforms,
        chunks.ThemingUniform,
        chunks.PointSizeUniforms,
        chunks.WideLinesUniforms, 
        chunks.DepthTextureTestUniforms,
        ...newThreejsUniforms
    ] ),

    vertexShader: basic_vert,
    fragmentShader: basic_frag
};

THREE.ShaderLib['firefly_basic'] = BasicShader;
